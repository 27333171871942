import type {AccountSettings} from '~/stores/accountSettings';

export default defineNuxtRouteMiddleware(async _ => {
  const {isAuth} = useAuthorizationStore();

  if (!isAuth) return;

  const {setAccountSettings, fetchAccountProfileSettings} =
    useAccountSettingsStore();

  const {status, data} = await fetchAccountProfileSettings();

  if (status.value === 'success') {
    setAccountSettings(data.value as AccountSettings);
  } else {
    await clearError({redirect: '/'});
  }
});
